<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<v-row dense>
						<!-- NAME -->
						<v-col cols="4">
							<pui-text-field
								:id="`name-mpollutionalertsauthorities`"
								v-model="model.name"
								:label="$t('mpollutionalertsauthorities.name')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="100"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- ADDRESS -->
						<v-col cols="4">
							<pui-text-field
								:id="`address-mpollutionalertsauthorities`"
								v-model="model.address"
								:label="$t('mpollutionalertsauthorities.address')"
								:disabled="formDisabled"
								toplabel
								maxlength="100"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- FAXES -->
						<v-col cols="4">
							<pui-text-field
								:id="`faxes-mpollutionalertsauthorities`"
								v-model="model.faxes"
								:label="$t('mpollutionalertsauthorities.faxes')"
								:disabled="formDisabled"
								toplabel
								maxlength="100"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- EMAILS -->
						<v-col cols="4">
							<pui-text-field
								:id="`emails-mpollutionalertsauthorities`"
								v-model="model.emails"
								:label="$t('mpollutionalertsauthorities.emails')"
								:disabled="formDisabled"
								toplabel
								maxlength="100"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- COMMENTS -->
						<v-col cols="4">
							<pui-text-field
								:id="`comments-mpollutionalertsauthorities`"
								v-model="model.comments"
								:label="$t('mpollutionalertsauthorities.comments')"
								:disabled="formDisabled"
								toplabel
								maxlength="256"
							></pui-text-field>
						</v-col>
					</v-row>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'mpollutionalertsauthorities-form',
	mixins: [PuiFormMethodsMixin],
	components: {},
	data() {
		return {
			modelName: 'mpollutionalertsauthorities'
		};
	},
	methods: {
		afterGetData() {
			// Do something after get data from server
		}
	},
	computed: {},
	created() {}
};
</script>
